<template>
  <el-dialog
      title="复制"
      :visible.sync="dialogVisible"
      @open="getCategory"
      @close="handleClose"
      >
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="资讯标题">
        <el-input v-model="form.c_title" disabled></el-input>
      </el-form-item>
      <el-form-item label="语言">
        <el-select v-model="form.lang" :visible-change="true" @change="change($event)" placeholder="请选择">
          <el-option
              v-for="item in LangList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属栏目">
        <el-select v-model="form.cate_id"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

</template>

<script>
import axios from "axios";

export default {
  name: "getCopyView",
  data(){
    return{
      dialogVisible:this.show,
      fileList:[],
      form: {
        'id' : '',
        'c_title' : ''
      },
      cateList: [],
      LangList: [
        {'label':'中文','value':'_cn'},
        {'label':'English','value':'_en'},
        {'label':'Italiano','value':'_it'},
        {'label':'日本語','value':'_jp'}
      ]
    }
  },
  watch:{
    show(val){
      this.dialogVisible = val
      // this.resetData();
    }
  },
  props:{
    show: {
      type: Boolean,
      default: false
    },
    id:{
      type: [String,Number],
      default: '0'
    }
  },
  // created() {
  //   this.getCategory();
  // },
  methods:{
    change($event){
      axios.get('/api/Copy/easyList.html?lang='+$event).then((res)=>{
        this.cateList = res.data;
      });
    },
    getCategory() {
      let id = this.$props.id;
      this.form.id = id;
      axios.get('/api/Copy/getProduct.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form.c_title = res.data.data;
        }
      });
    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.$emit("dialogShowChange",false);
      this.resetData()
    },
    onSubmit() {
      axios({
        method:'post',
        url:'/api/Copy/setProduct.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          location.reload()
          // this.$router.go(0);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>