<template>
  <div>
    <div> <p  class="category">资讯管理</p>
      <el-button size="mini" @click="goTo(0)" class="category add">新增</el-button></div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="资讯标题"
          >
        <template slot-scope="scope">
          <span style="margin-left: 10px" v-html="scope.row.c_title"></span>
        </template>
      </el-table-column>
      <el-table-column
          label="缩略图"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px"><img :src="scope.row.c_img" style="max-width: 150px;max-height: 50px;"/></span>
        </template>
      </el-table-column>
      <el-table-column
          label="状态"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.c_status}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">

          <el-button size="mini" @click="goTo(scope.row.id)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="deleteContent(scope.$index,scope.row)">删除</el-button>
          <el-button size="mini" @click="openCate(scope.row.id)">复制</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
        >
    </el-pagination>
    <Dialog  :id='id' :show.sync="dialogVisible" @dialogShowChange="dialogShowChange"></Dialog>
  </div>
</template>
<script>
import axios from "axios";
import Dialog from "@/views/pages/getCopyView.vue";


export default {
  name: "ContentView",
  data() {
    return {
      dialogVisible: false, //当前的dialog是不是显示
      tableData: [],
      id: '',
      total: 100,
    }
  },
  components:{
    Dialog,
  },
  created() {
    this.getCate();
  },
  methods: {
    openCate(id){
      this.id = id
      this.dialogVisible =true
    },
    dialogShowChange(val){
      this.dialogVisible = val
    },
    goTo(id){
      this.$router.push('/getContent?id='+id)
    },
    getCate(page = 1){
      axios.get('/api/Content/index.html?page='+page).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    },
    deleteContent(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Content/deleteContent.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style scoped lang=''>
</style>