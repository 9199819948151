import { render, staticRenderFns } from "./getCopyView.vue?vue&type=template&id=5d79fc54&scoped=true&"
import script from "./getCopyView.vue?vue&type=script&lang=js&"
export * from "./getCopyView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d79fc54",
  null
  
)

export default component.exports